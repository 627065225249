<div class="wrapper">
  <h1>Email Verification</h1>
  <ng-container *ngrxLet="isVerified$ as verified">
    <ng-container *ngrxLet="user$ as user">
      <ng-container *ngIf="verified && user">
        <p>Success!</p>
        <p>Thank you for confirming your email. <a [routerLink]="['/projects']">Click here to get started.</a></p>
      </ng-container>
      <ng-container *ngIf="verified && !user">
        <p>Success!</p>
        <p>Thank you for confirming your email. <a [routerLink]="['/login']">Click here to login.</a></p>
      </ng-container>
      <ng-container *ngIf="verified === false && user">
        <p>Something went wrong&hellip;</p>
        <p>That didn&apos;t work. Sorry!</p>
        <button class="button" *ngIf="user">Resend Verification
          Link</button>
      </ng-container>
      <ng-container *ngIf="verified === false && !user">
        <p>Something went wrong&hellip;</p>
        <p><a [routerLink]="['/login']">Pleasse login</a> to resend the verification email.</p>
      </ng-container>
    </ng-container>
  </ng-container>
</div>