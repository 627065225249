import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/guards/auth.guard';
import { TypographyComponent } from './components/styleguide/typography/typography.component';
import { LoginComponent } from './containers/pages/login/login.component';
import { VerifyEmailComponent } from './containers/pages/verify-email/verify-email.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'verify-email', component: VerifyEmailComponent },
  { path: 'styleguide/typography', component: TypographyComponent },
  {
    path: 'user',
    loadChildren: () =>
      import('./modules/user/user.module').then((m) => m.UserModule),
    canLoad: [AuthGuard]
  },
  // {
  //   path: 'characters',
  //   loadChildren: () =>
  //     import('./characters/characters.module').then((m) => m.CharactersModule)
  // },
  {
    path: 'projects',
    loadChildren: () =>
      import('./modules/projects/projects.module').then(
        (m) => m.ProjectsModule
      ),
    canLoad: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
