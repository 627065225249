import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { UserModel, UserSelectors, UserState } from '../app-store';

@Injectable({
  providedIn: 'root'
})
export class VerifiedUserGuard implements CanActivate {
  user$: Observable<UserModel.User>;

  constructor(private userStore: Store<UserState.State>) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.user$ = this.userStore.select(UserSelectors.selectUser);
    return this.user$.pipe(
      filter((user) => !!user),
      switchMap((user) => {
        return of(user.emailVerified);
      }),
      take(1),
      catchError(() => of(false))
    );
  }
}
