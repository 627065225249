import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreModule
} from '@angular/fire/firestore';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppStoreModule } from '@app/app-store';
import { AppComponent } from '@app/app.component';
import { LogoComponent } from '@app/components/atoms/logo/logo.component';
import { FooterComponent } from '@app/components/molecules/footer/footer.component';
import { HeaderComponent } from '@app/components/molecules/header/header.component';
import { NavMainComponent } from '@app/components/molecules/nav-main/nav-main.component';
import { LoginFormComponent } from '@app/components/organisms/login-form/login-form.component';
import { SignupFormComponent } from '@app/components/organisms/signup-form/signup-form.component';
import { TypographyComponent } from '@app/components/styleguide/typography/typography.component';
import { LoginComponent } from '@app/containers/pages/login/login.component';
import { VerifiedUserGuard } from '@app/guards/verified-user.guard';
import { ReactiveComponentModule } from '@ngrx/component';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { environment } from '../environments/environment';
import { ToastComponent } from './components/organisms/toast/toast.component';
import { VerifyEmailComponent } from './containers/pages/verify-email/verify-email.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavMainComponent,
    FooterComponent,
    LogoComponent,
    HeaderComponent,
    TypographyComponent,
    LoginFormComponent,
    SignupFormComponent,
    ToastComponent,
    VerifyEmailComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),
    BrowserAnimationsModule,
    RouterModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    InputTextModule,
    ButtonModule,
    AppStoreModule,
    StoreModule.forRoot({}, {}),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production // Restrict extension to log-only mode
    }),
    ReactiveComponentModule,
    ToastModule
  ],
  providers: [VerifiedUserGuard, AngularFirestore],
  bootstrap: [AppComponent]
})
export class AppModule {}
