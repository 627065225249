import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UiEffects } from './effects';
import { uiReducer } from './reducers';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('ui', uiReducer),
    EffectsModule.forFeature([UiEffects])
  ],
  providers: [UiEffects]
})
export class UiStoreModule {}
