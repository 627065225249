import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserState } from './app-store';
import { GetCurrentUserAction } from './app-store/user-store/actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'authorshipit-client';

  constructor(private userStore: Store<UserState.State>) {}

  ngOnInit() {
    this.userStore.dispatch(new GetCurrentUserAction());
  }
}
