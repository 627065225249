import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreCollection
} from '@angular/fire/firestore';
import { User as UserModel } from '@app/app-store/user-store/model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userCollection: AngularFirestoreCollection;

  constructor(
    private firestore: AngularFirestore,
    private afAuth: AngularFireAuth
  ) {
    // this.getUser();
  }

  /**
   * Only allow fetching against the actual logged in user to eliminate tom-foolery
   */
  // getUser() {
  //   console.log('getUser');
  //   if (this.afAuth.currentUser !== null) {
  //     this.userCollection = this.firestore.collection('users', (ref) =>
  //       ref.where('uid', '==', this.afAuth.currentUser.uid)
  //     );

  //     this.userCollection.valueChanges().subscribe((users) => {});
  //   }
  // }
}
