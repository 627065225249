import { ReturnStatement } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { auth } from 'firebase/app';
import { from, Observable, of } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    public afAuth: AngularFireAuth,
    public router: Router,
    private userService: UserService
  ) {}

  login(email: string, password: string): Observable<auth.UserCredential> {
    return from(this.afAuth.signInWithEmailAndPassword(email, password));
  }

  loginWithGoogle() {
    return from(this.afAuth.signInWithPopup(new auth.GoogleAuthProvider()));
  }

  getCurrentUser(): Observable<firebase.User> {
    console.log('getCurrentUser');
    return this.afAuth.authState;
  }

  signup(email: string, password: string): Observable<auth.UserCredential> {
    return from(this.afAuth.createUserWithEmailAndPassword(email, password));
  }

  sendEmailVerification(): Observable<void> {
    // get the fbUser from store user
    console.log('sendEmailVerification');
    return from(this.afAuth.currentUser).pipe(
      first(),
      mergeMap((user: firebase.User) => user.sendEmailVerification())
    );
    // .then((user: firebase.User) => user.sendEmailVerification())
    // );
    // return from(.sendEmailVerification());
    // TODO: add a route and page for email verification
    // this.router.navigate(['admin/verify-email']);
  }

  handleEmailVerificationCode(code: string): Observable<any> {
    return from(
      this.afAuth
        .applyActionCode(code)
        .then((response) => {
          // Email address has been verified.
          console.log('applyActionCode response', response);
          return response;
          // TODO: Display a confirmation message to the user.
          // You could also provide the user with a link back to the app.

          // TODO: If a continue URL is available, display a button which on
          // click redirects the user back to the app via continueUrl with
          // additional state determined from that URL's parameters.
        })
        .catch((error) => {
          console.error(error);
          throw error;
          // Code is invalid or expired. Ask the user to verify their email address
          // again.
        })
    );
  }

  sendPasswordResetEmail(passwordResetEmail: string) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail);
  }

  logout() {
    return from(this.afAuth.signOut());
  }

  // isLoggedIn(): Observable<boolean> {
  //   if (this._isLoggedIn === undefined) {
  //     return this.afAuth.authState.pipe(
  //       first(),
  //       map((user) => {
  //         if (user) {
  //           return true;
  //         }
  //       })
  //     );
  //   }

  //   return of(this._isLoggedIn);
  // }
}
