import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserModel, UserSelectors, UserState } from '@app/app-store';
import { AuthService } from '@app/services/auth.service';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';

interface EmailVerificationParams {
  mode: string;
  oobCode: string;
  lang: string;
  apiKey: string;
  continueUrl: string;
}

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerifyEmailComponent implements OnInit {
  queryParams: EmailVerificationParams;
  isVerified$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  user$: Observable<UserModel.User>;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private userStore: Store<UserState.State>
  ) {
    this.user$ = this.userStore.select(UserSelectors.selectUser);
  }

  ngOnInit(): void {
    this.route.queryParams
      .pipe(first())
      .subscribe((params: EmailVerificationParams) => {
        this.queryParams = { ...params };
        if (params.oobCode) {
          this.handleVerificationCode(params.oobCode, params.continueUrl);
        }
      });
  }

  handleVerificationCode(code: string, continueUrl?: string): void {
    if (!code) {
      return null;
    }

    this.authService
      .handleEmailVerificationCode(code)
      .pipe(
        first(),
        map(() => {
          console.info('redirect to continueurl if exists', continueUrl);
          this.isVerified$.next(true);
          // cheKnot link to login
        }),
        catchError((err, caught) => {
          this.isVerified$.next(false);
          console.error(err);
          return of(err);
        })
      )
      .subscribe();
  }
}
