import { createSelector, MemoizedSelector } from '@ngrx/store';
import { UserSelectors } from './user-store';

export const selectError: MemoizedSelector<object, string> = createSelector(
  UserSelectors.selectUserError,
  // MyOtherFeatureStoreSelectors.selectMyOtherFeatureError,
  (userError: string) => {
    return userError;
    // return userError || myOtherFeature;
  }
);

export const selectIsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(
  UserSelectors.selectUserIsLoading,
  // MyOtherFeatureStoreSelectors.selectMyOtherFeatureIsLoading,
  (user: boolean) => {
    return user;
  }
);
