<form class="login-form" [formGroup]="loginForm" (ngSubmit)="formSubmitted()">
  <span class="p-float-label">
    <input type="email" formControlName="username" name="email" placeholder="" pInputText />
    <label for="email">Email</label>
  </span>
  <span class="p-float-label">
    <input type="password" formControlName="password" name="password" placeholder="" pInputText />
    <label for="password">Password</label>
  </span>
  <div class="button-group">
    <button pButton class="form__field p-button-raised with-icon email-icon" [disabled]="!loginForm.valid" type="submit"
      label="Sign In With Email"></button>
    <button pButton class="form__field p-button-secondary p-button-raised google-logo with-icon"
      (click)="loginWithGoogle()" type="button" label="Sign In With Google"></button>
  </div>
</form>