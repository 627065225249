import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UiActions, UiSelectors, UiState } from '@app/app-store';
import { UiMessage } from '@app/app-store/ui-store/model';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService]
})
export class ToastComponent implements OnInit {
  messages$: Observable<UiMessage[]>;

  constructor(
    private uiStore: Store<UiState.State>,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.messages$ = this.uiStore.select(UiSelectors.selectMessages);
    this.messages$
      .pipe(
        filter((messages) => messages !== null),
        tap((messages: UiMessage[]) => {
          console.log('tap!');
          this.messageService.addAll(messages);
          this.uiStore.dispatch(UiActions.clearMessages());
        })
      )
      .subscribe();
  }
}
