<div class="wrapper">
  <div class="signup-login-box">
    <section class="login form-wrapper active">
      <h1>Sign In</h1>
      <app-login-form></app-login-form>
    </section>
    <section class="signup form-wrapper">
      <h1>Sign Up</h1>
      <app-signup-form></app-signup-form>
    </section>
  </div>
</div>