<nav class="nav">
  <a class="nav__link" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a>
  <ng-container *ngIf="user$ | ngrxPush as user; else loggedOut">
    <a *ngIf="user.emailVerified === true" class="nav__link" routerLink="/projects"
      routerLinkActive="active">Projects</a>
    <a *ngIf="user.emailVerified === true" class="nav__link" routerLink="/characters"
      routerLinkActive="active">Characters</a>
    <a *ngIf="user !== null" class="nav__link" routerLink="/user/profile" routerLinkActive="active">Account</a>
  </ng-container>
  <ng-template #loggedOut>
    <a class="nav__link" routerLink="/login" routerLinkActive="active"
      [routerLinkActiveOptions]="{exact: true}">Login</a>
  </ng-template>
</nav>