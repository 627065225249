import { Action } from '@ngrx/store';
import { User } from '../../models';

export enum ActionTypes {
  GET_CURRENT_USER = '[User] Get Current User',
  LOGIN_GOOGLE_REQUEST = '[User] Login via Google Request',
  LOGIN_FAILURE = '[User] Login Failure',
  LOGIN_REQUEST = '[User] Login Request',
  LOGIN_SUCCESS = '[User] Login Success',
  LOGOUT = '[User] Logout',
  SET_USER = '[User] Set User',
  SIGNUP_REQUEST = '[User] Signup User',
  SIGNUP_FAILURE = '[User] Signup Failure',
  SIGNUP_SUCCESS = '[User] Signup Success',
  SEND_VERIFICATION_EMAIL = '[User] Send Verification Email'
}

export class GetCurrentUserAction implements Action {
  readonly type = ActionTypes.GET_CURRENT_USER;
}

export class LogoutAction implements Action {
  readonly type = ActionTypes.LOGOUT;
}

export class LoginRequestAction implements Action {
  readonly type = ActionTypes.LOGIN_REQUEST;
  constructor(public payload: { email: string; password: string }) {}
}

export class LoginGoogleRequestAction implements Action {
  readonly type = ActionTypes.LOGIN_GOOGLE_REQUEST;
}

export class LoginFailureAction implements Action {
  readonly type = ActionTypes.LOGIN_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoginSuccessAction implements Action {
  readonly type = ActionTypes.LOGIN_SUCCESS;
  constructor(public payload: { user: User }) {}
}

export class SignupRequestAction implements Action {
  readonly type = ActionTypes.SIGNUP_REQUEST;
  constructor(public payload: { email: string; password: string }) {}
}

export class SetUserAction implements Action {
  readonly type = ActionTypes.SET_USER;
  constructor(public payload: { user: User }) {}
}

export class SendVerificationEmailAction implements Action {
  readonly type = ActionTypes.SEND_VERIFICATION_EMAIL;
  constructor() {}
}

export type Actions =
  | LoginRequestAction
  | LoginFailureAction
  | LoginSuccessAction
  | LogoutAction
  | GetCurrentUserAction
  | SetUserAction
  | SignupRequestAction
  | SendVerificationEmailAction;
