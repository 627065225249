import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { UserState } from '@app/app-store';
import {
  LoginGoogleRequestAction,
  SignupRequestAction
} from '@app/app-store/user-store/actions';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignupFormComponent implements OnInit {
  signupForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private userStore: Store<UserState.State>
  ) {
    this.signupForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {}

  public formSubmitted(): void {
    if (!this.signupForm.valid) {
      return;
    }

    this.userStore.dispatch(
      new SignupRequestAction({
        email: this.signupForm.get('username').value,
        password: this.signupForm.get('password').value
      })
    );
  }

  public signupWithGoogle(): void {
    this.userStore.dispatch(new LoginGoogleRequestAction());
  }
}
