import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { UserState } from '@app/app-store';
import {
  LoginGoogleRequestAction,
  LoginRequestAction
} from '@app/app-store/user-store/actions';
import { Store } from '@ngrx/store';
import { FormBuilder, FormGroup } from '@ngneat/reactive-forms';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {
  loginForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private userStore: Store<UserState.State>
  ) {
    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['']
    });
  }

  public formSubmitted(): void {
    if (!this.loginForm.valid) {
      return;
    }

    this.userStore.dispatch(
      new LoginRequestAction({
        email: this.loginForm.get('username').value,
        password: this.loginForm.get('password').value
      })
    );
  }

  public loginWithGoogle(): void {
    this.userStore.dispatch(new LoginGoogleRequestAction());
  }
}
