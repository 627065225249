import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { UserModel, UserSelectors, UserState } from '../app-store';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {
  user$: Observable<UserModel.User>;

  constructor(private userStore: Store<UserState.State>) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.user$ = this.userStore.select(UserSelectors.selectUser);
    return this.user$.pipe(
      switchMap((user) => {
        return of(user && !!user.uid);
      }),
      take(1),
      catchError(() => of(false))
    );
  }
}
