import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { from, Observable, of as observableOf } from 'rxjs';
import {
  catchError,
  first,
  map,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { UiActions, UiState } from '../ui-store';
import * as featureActions from './actions';
import { User } from './model';

@Injectable()
export class UserEffects {
  constructor(
    private authService: AuthService,
    private actions$: Actions,
    private router: Router,
    private uiStore: Store<UiState.State>
  ) {}

  loginSuccess$: Observable<boolean> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<featureActions.LogoutAction>(
          featureActions.ActionTypes.LOGIN_SUCCESS
        ),
        switchMap(() => from(this.router.navigate(['/'])))
      ),
    { dispatch: false }
  );

  logout$: Observable<void> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<featureActions.LogoutAction>(featureActions.ActionTypes.LOGOUT),
        switchMap(() =>
          this.authService.logout().pipe(
            first(),
            tap(() => {
              console.log('logged out');
              return from(this.router.navigate(['/']));
            })
          )
        )
      ),
    { dispatch: false }
  );

  getCurrentUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.GetCurrentUserAction>(
        featureActions.ActionTypes.GET_CURRENT_USER
      ),
      switchMap(() =>
        this.authService.getCurrentUser().pipe(
          map((fbUser: firebase.User) => {
            const user = fbUser ? this.mapFirebaseUserToLocal(fbUser) : null;
            if (user && !user.emailVerified) {
              this.uiStore.dispatch(
                UiActions.addMessage({
                  message: {
                    summary: 'Verify Email',
                    detail:
                      'Please check your email and follow the link to verify your email address to enjoy the full experience.',
                    severity: 'info',
                    sticky: true
                  }
                })
              );
            }
            return new featureActions.SetUserAction({ user });
          })
        )
      )
    )
  );

  /**
   * loginRequestEffect
   *
   * This observable is created from a promise so it is self-terminating.
   */
  loginRequestEffect$: Observable<
    featureActions.LoginSuccessAction | featureActions.LoginFailureAction
  > = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoginRequestAction>(
        featureActions.ActionTypes.LOGIN_REQUEST
      ),
      switchMap((action) =>
        this.authService
          .login(action.payload.email, action.payload.password)
          .pipe(
            map((userCredentials) => {
              const user: User = this.mapFirebaseUserToLocal(
                userCredentials.user
              );
              return new featureActions.LoginSuccessAction({ user });
            }),
            catchError((error) => {
              let message = 'Unknown Error';

              switch (error.code) {
                case 'auth/wrong-password':
                  message = 'Wrong password. Try again?';
                  break;
              }
              console.error(message);
              return observableOf(
                new featureActions.LoginFailureAction({ error: message })
              );
            })
          )
      )
    )
  );

  loginGoogleRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoginGoogleRequestAction>(
        featureActions.ActionTypes.LOGIN_GOOGLE_REQUEST
      ),
      switchMap(() =>
        this.authService.loginWithGoogle().pipe(
          map((userCredentials) => {
            const user: User = this.mapFirebaseUserToLocal(
              userCredentials.user
            );
            return new featureActions.LoginSuccessAction({ user });
          }),
          catchError((error) => {
            let message = 'Unknown Error';

            switch (error.code) {
              case 'auth/wrong-password':
                message = 'Google login failed. Try again?';
                break;
            }

            console.error(message);
            return observableOf(
              new featureActions.LoginFailureAction({ error: message })
            );
          })
        )
      )
    )
  );

  // @Effect({ dispatch: false })
  // loginFailureEffect$: Observable<Action> = this.actions$.pipe(
  //   ofType<featureActions.LoginFailureAction>(
  //     featureActions.ActionTypes.LOGIN_FAILURE
  //   ),
  //   tap((action) => {
  //     console.error(action.payload);
  //   }),
  //   switchMap((action) => {
  //     return from(action.payload.error);
  //   })
  // );

  /**
   * signupRequestEffect
   *
   * This observable is created from a promise so it is self-terminating.
   */
  signupRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.SignupRequestAction>(
        featureActions.ActionTypes.SIGNUP_REQUEST
      ),
      switchMap((action) =>
        this.authService
          .signup(action.payload.email, action.payload.password)
          .pipe(
            switchMap((userCredentials) => {
              console.log('signup user', userCredentials);
              const user: User = this.mapFirebaseUserToLocal(
                userCredentials.user
              );
              // this.authService.sendEmailVerification();
              // TODO: Signup Success here
              return [
                new featureActions.SendVerificationEmailAction(),
                new featureActions.LoginSuccessAction({ user })
              ];
            }),
            catchError((error) => {
              console.log('error', error);
              let message = 'Unknown Error';

              switch (error.code) {
                case 'auth/wrong-password':
                  message = 'Wrong password. Try again?';
                  break;
              }
              console.error(message);
              return observableOf(
                new featureActions.LoginFailureAction({ error: message })
              );
            })
          )
      )
    )
  );

  /**
   * reendVerificationEmailEffect
   *
   *
   */
  sendVerificationEmailEffect$: Observable<void> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<featureActions.SendVerificationEmailAction>(
          featureActions.ActionTypes.SEND_VERIFICATION_EMAIL
        ),
        mergeMap(() => {
          console.log('SendVerificationEmailEffect');
          return this.authService.sendEmailVerification();
        })
      ),
    { dispatch: false }
  );

  mapFirebaseUserToLocal(fbUser: firebase.User): User {
    return {
      uid: fbUser.uid,
      email: fbUser.email,
      emailVerified: fbUser.emailVerified,
      name: fbUser.displayName,
      image: fbUser.photoURL
    };
  }
}
