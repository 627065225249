import { Component, OnInit } from '@angular/core';
import { UserModel, UserSelectors, UserState } from '@app/app-store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-nav-main',
  templateUrl: './nav-main.component.html',
  styleUrls: ['./nav-main.component.scss']
})
export class NavMainComponent implements OnInit {
  user$: Observable<UserModel.User>;

  constructor(private userStore: Store<UserState.State>) {
    this.user$ = this.userStore.select(UserSelectors.selectUser);
  }

  ngOnInit(): void {}
}
