import { Action, createReducer, on } from '@ngrx/store';
import * as UiActions from './actions';
import { UiMessage } from './model';
import { initialState } from './state';
export const uiFeatureKey = 'ui';

export const uiReducer = createReducer(
  initialState,

  on(UiActions.loadUis, (state) => state),
  on(UiActions.loadUisSuccess, (state, action) => state),
  on(UiActions.loadUisFailure, (state, action) => state),
  on(UiActions.addMessage, (state, action) => {
    const newMessage: UiMessage = {
      severity: 'success',
      sticky: false,
      ...action.message
    };
    console.log('newMessage', newMessage);
    const messages = state.messages
      ? [...state.messages, newMessage]
      : [newMessage];

    return {
      ...state,
      messages
    };
  }),
  on(UiActions.clearMessages, (state, action) => {
    return { ...state, messages: null };
  })
);
