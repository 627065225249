import { createAction, props } from '@ngrx/store';
import { UiMessage } from './model';

export const loadUis = createAction('[Ui] Load Uis');

export const loadUisSuccess = createAction(
  '[Ui] Load Uis Success',
  props<{ data: any }>()
);

export const loadUisFailure = createAction(
  '[Ui] Load Uis Failure',
  props<{ error: any }>()
);

export const addMessage = createAction(
  '[Ui] Add Message',
  props<{
    message: UiMessage;
  }>()
);

export const clearMessages = createAction('[Ui] Clear Messages');
