import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector
} from '@ngrx/store';
import { UiState } from '.';
import { UiMessage } from './model';
import * as fromUi from './reducers';

const getMessages = (state: UiState.State): UiMessage[] | null =>
  state.messages;

export const selectUiState = createFeatureSelector<UiState.State>(
  fromUi.uiFeatureKey
);

// export const selectUserState: MemoizedSelector<
//   object,
//   State
// > = createFeatureSelector<State>('user');

// export const selectMessages = createFeatureSelector('user');
// > = createFeatureSelector<UiState.State>('user');

export const selectMessages: MemoizedSelector<
  object,
  UiMessage[] | null
> = createSelector(selectUiState, getMessages);
