<div class="typography">
  <h1 class="font-xxl">H1/XXL Text Headline (<span class="metrics"></span>)</h1>
  <p class="body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
    dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
    commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
    laborum. Semper aeterno percipit ut his, sea ex utinam referrentur repudiandae. Ne assum fierent his, cibo eruditi
    necessitatibus id vix, cum tantas audiam reprehendunt in. Pri posse graeco definitiones cu, id eam populo quaestio
    adipiscing, usu quod malorum te. Elitr iudico dignissim ea sed, duis moderatius definitiones no cum. Offendit
    eleifend moderatius ex vix, quem odio mazim et qui, purto expetendis cotidieque quo cu, veri persius vituperata ei
    nec. Ad per diam dicant interesset, lorem iusto sensibus ut sed. Pri posse graeco definitiones cu, id eam populo
    quaestio adipiscing, usu quod malorum te. Offendit eleifend moderatius ex vix, quem odio mazim et qui, purto
    expetendis cotidieque quo cu, veri persius vituperata ei nec. Scripta periculis ei eam, te pro movet reformidans.
    Pri posse graeco definitiones cu, id eam populo quaestio adipiscing, usu quod malorum te.</p>
  <p class="body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
    dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
    commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
    laborum. Semper aeterno percipit ut his, sea ex utinam referrentur repudiandae. Ne assum fierent his, cibo eruditi
    necessitatibus id vix, cum tantas audiam reprehendunt in. Pri posse graeco definitiones cu, id eam populo quaestio
    adipiscing, usu quod malorum te. Elitr iudico dignissim ea sed, duis moderatius definitiones no cum. Offendit
    eleifend moderatius ex vix, quem odio mazim et qui, purto expetendis cotidieque quo cu, veri persius vituperata ei
    nec. Ad per diam dicant interesset, lorem iusto sensibus ut sed. Pri posse graeco definitiones cu, id eam populo
    quaestio adipiscing, usu quod malorum te. Offendit eleifend moderatius ex vix, quem odio mazim et qui, purto
    expetendis cotidieque quo cu, veri persius vituperata ei nec. Scripta periculis ei eam, te pro movet reformidans.
    Pri posse graeco definitiones cu, id eam populo quaestio adipiscing, usu quod malorum te.</p>
  <h2 class="font-xl">XL 27.34/21 Absolution Apathetique</h2>
  <p class="body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
    dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
    commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
    laborum. Semper aeterno percipit ut his, sea ex utinam referrentur repudiandae. Ne assum fierent his, cibo eruditi
    necessitatibus id vix, cum tantas audiam reprehendunt in. Pri posse graeco definitiones cu, id eam populo quaestio
    adipiscing, usu quod malorum te. Elitr iudico dignissim ea sed, duis moderatius definitiones no cum. Offendit
    eleifend moderatius ex vix, quem odio mazim et qui, purto expetendis cotidieque quo cu, veri persius vituperata ei
    nec. Ad per diam dicant interesset, lorem iusto sensibus ut sed. Pri posse graeco definitiones cu, id eam populo
    quaestio adipiscing, usu quod malorum te. Offendit eleifend moderatius ex vix, quem odio mazim et qui, purto
    expetendis cotidieque quo cu, veri persius vituperata ei nec. Scripta periculis ei eam, te pro movet reformidans.
    Pri posse graeco definitiones cu, id eam populo quaestio adipiscing, usu quod malorum te.</p>
  <p class="body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
    dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
    commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
    laborum. Semper aeterno percipit ut his, sea ex utinam referrentur repudiandae. Ne assum fierent his, cibo eruditi
    necessitatibus id vix, cum tantas audiam reprehendunt in. Pri posse graeco definitiones cu, id eam populo quaestio
    adipiscing, usu quod malorum te. Elitr iudico dignissim ea sed, duis moderatius definitiones no cum. Offendit
    eleifend moderatius ex vix, quem odio mazim et qui, purto expetendis cotidieque quo cu, veri persius vituperata ei
    nec. Ad per diam dicant interesset, lorem iusto sensibus ut sed. Pri posse graeco definitiones cu, id eam populo
    quaestio adipiscing, usu quod malorum te. Offendit eleifend moderatius ex vix, quem odio mazim et qui, purto
    expetendis cotidieque quo cu, veri persius vituperata ei nec. Scripta periculis ei eam, te pro movet reformidans.
    Pri posse graeco definitiones cu, id eam populo quaestio adipiscing, usu quod malorum te.</p>
  <h3 class="font-l">H3 21.88/21 Absolution Apathetique</h3>
  <p class="body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
    dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
    commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
    laborum. Semper aeterno percipit ut his, sea ex utinam referrentur repudiandae. Ne assum fierent his, cibo eruditi
    necessitatibus id vix, cum tantas audiam reprehendunt in. Pri posse graeco definitiones cu, id eam populo quaestio
    adipiscing, usu quod malorum te. Elitr iudico dignissim ea sed, duis moderatius definitiones no cum. Offendit
    eleifend moderatius ex vix, quem odio mazim et qui, purto expetendis cotidieque quo cu, veri persius vituperata ei
    nec. Ad per diam dicant interesset, lorem iusto sensibus ut sed. Pri posse graeco definitiones cu, id eam populo
    quaestio adipiscing, usu quod malorum te. Offendit eleifend moderatius ex vix, quem odio mazim et qui, purto
    expetendis cotidieque quo cu, veri persius vituperata ei nec. Scripta periculis ei eam, te pro movet reformidans.
    Pri posse graeco definitiones cu, id eam populo quaestio adipiscing, usu quod malorum te.</p>
  <p class="body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
    dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
    commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
    laborum. Semper aeterno percipit ut his, sea ex utinam referrentur repudiandae. Ne assum fierent his, cibo eruditi
    necessitatibus id vix, cum tantas audiam reprehendunt in. Pri posse graeco definitiones cu, id eam populo quaestio
    adipiscing, usu quod malorum te. Elitr iudico dignissim ea sed, duis moderatius definitiones no cum. Offendit
    eleifend moderatius ex vix, quem odio mazim et qui, purto expetendis cotidieque quo cu, veri persius vituperata ei
    nec. Ad per diam dicant interesset, lorem iusto sensibus ut sed. Pri posse graeco definitiones cu, id eam populo
    quaestio adipiscing, usu quod malorum te. Offendit eleifend moderatius ex vix, quem odio mazim et qui, purto
    expetendis cotidieque quo cu, veri persius vituperata ei nec. Scripta periculis ei eam, te pro movet reformidans.
    Pri posse graeco definitiones cu, id eam populo quaestio adipiscing, usu quod malorum te.</p>
  <h4 class="font-m">H4 17.50/21 Absolution Apathetique</h4>
  <p class="body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
    dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
    commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
    laborum. Semper aeterno percipit ut his, sea ex utinam referrentur repudiandae. Ne assum fierent his, cibo eruditi
    necessitatibus id vix, cum tantas audiam reprehendunt in. Pri posse graeco definitiones cu, id eam populo quaestio
    adipiscing, usu quod malorum te. Elitr iudico dignissim ea sed, duis moderatius definitiones no cum. Offendit
    eleifend moderatius ex vix, quem odio mazim et qui, purto expetendis cotidieque quo cu, veri persius vituperata ei
    nec. Ad per diam dicant interesset, lorem iusto sensibus ut sed. Pri posse graeco definitiones cu, id eam populo
    quaestio adipiscing, usu quod malorum te. Offendit eleifend moderatius ex vix, quem odio mazim et qui, purto
    expetendis cotidieque quo cu, veri persius vituperata ei nec. Scripta periculis ei eam, te pro movet reformidans.
    Pri posse graeco definitiones cu, id eam populo quaestio adipiscing, usu quod malorum te.</p>
  <p class="body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
    dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
    commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
    laborum. Semper aeterno percipit ut his, sea ex utinam referrentur repudiandae. Ne assum fierent his, cibo eruditi
    necessitatibus id vix, cum tantas audiam reprehendunt in. Pri posse graeco definitiones cu, id eam populo quaestio
    adipiscing, usu quod malorum te. Elitr iudico dignissim ea sed, duis moderatius definitiones no cum. Offendit
    eleifend moderatius ex vix, quem odio mazim et qui, purto expetendis cotidieque quo cu, veri persius vituperata ei
    nec. Ad per diam dicant interesset, lorem iusto sensibus ut sed. Pri posse graeco definitiones cu, id eam populo
    quaestio adipiscing, usu quod malorum te. Offendit eleifend moderatius ex vix, quem odio mazim et qui, purto
    expetendis cotidieque quo cu, veri persius vituperata ei nec. Scripta periculis ei eam, te pro movet reformidans.
    Pri posse graeco definitiones cu, id eam populo quaestio adipiscing, usu quod malorum te.</p>
  <h5 class="font-s">H5 14/21 Absolution Apathetique</h5>
  <p class="body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
    dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
    commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
    laborum. Semper aeterno percipit ut his, sea ex utinam referrentur repudiandae. Ne assum fierent his, cibo eruditi
    necessitatibus id vix, cum tantas audiam reprehendunt in. Pri posse graeco definitiones cu, id eam populo quaestio
    adipiscing, usu quod malorum te. Elitr iudico dignissim ea sed, duis moderatius definitiones no cum. Offendit
    eleifend moderatius ex vix, quem odio mazim et qui, purto expetendis cotidieque quo cu, veri persius vituperata ei
    nec. Ad per diam dicant interesset, lorem iusto sensibus ut sed. Pri posse graeco definitiones cu, id eam populo
    quaestio adipiscing, usu quod malorum te. Offendit eleifend moderatius ex vix, quem odio mazim et qui, purto
    expetendis cotidieque quo cu, veri persius vituperata ei nec. Scripta periculis ei eam, te pro movet reformidans.
    Pri posse graeco definitiones cu, id eam populo quaestio adipiscing, usu quod malorum te.</p>
  <p class="body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
    dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
    commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
    laborum. Semper aeterno percipit ut his, sea ex utinam referrentur repudiandae. Ne assum fierent his, cibo eruditi
    necessitatibus id vix, cum tantas audiam reprehendunt in. Pri posse graeco definitiones cu, id eam populo quaestio
    adipiscing, usu quod malorum te. Elitr iudico dignissim ea sed, duis moderatius definitiones no cum. Offendit
    eleifend moderatius ex vix, quem odio mazim et qui, purto expetendis cotidieque quo cu, veri persius vituperata ei
    nec. Ad per diam dicant interesset, lorem iusto sensibus ut sed. Pri posse graeco definitiones cu, id eam populo
    quaestio adipiscing, usu quod malorum te. Offendit eleifend moderatius ex vix, quem odio mazim et qui, purto
    expetendis cotidieque quo cu, veri persius vituperata ei nec. Scripta periculis ei eam, te pro movet reformidans.
    Pri posse graeco definitiones cu, id eam populo quaestio adipiscing, usu quod malorum te.</p>
  <p class="body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
    dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
    commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
    laborum. Semper aeterno percipit ut his, sea ex utinam referrentur repudiandae. Ne assum fierent his, cibo eruditi
    necessitatibus id vix, cum tantas audiam reprehendunt in. Pri posse graeco definitiones cu, id eam populo quaestio
    adipiscing, usu quod malorum te. Elitr iudico dignissim ea sed, duis moderatius definitiones no cum. Offendit
    eleifend moderatius ex vix, quem odio mazim et qui, purto expetendis cotidieque quo cu, veri persius vituperata ei
    nec. Ad per diam dicant interesset, lorem iusto sensibus ut sed. Pri posse graeco definitiones cu, id eam populo
    quaestio adipiscing, usu quod malorum te. Offendit eleifend moderatius ex vix, quem odio mazim et qui, purto
    expetendis cotidieque quo cu, veri persius vituperata ei nec. Scripta periculis ei eam, te pro movet reformidans.
    Pri posse graeco definitiones cu, id eam populo quaestio adipiscing, usu quod malorum te.</p>
  <p class="body">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
    dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
    commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
    laborum. Semper aeterno percipit ut his, sea ex utinam referrentur repudiandae. Ne assum fierent his, cibo eruditi
    necessitatibus id vix, cum tantas audiam reprehendunt in. Pri posse graeco definitiones cu, id eam populo quaestio
    adipiscing, usu quod malorum te. Elitr iudico dignissim ea sed, duis moderatius definitiones no cum. Offendit
    eleifend moderatius ex vix, quem odio mazim et qui, purto expetendis cotidieque quo cu, veri persius vituperata ei
    nec. Ad per diam dicant interesset, lorem iusto sensibus ut sed. Pri posse graeco definitiones cu, id eam populo
    quaestio adipiscing, usu quod malorum te. Offendit eleifend moderatius ex vix, quem odio mazim et qui, purto
    expetendis cotidieque quo cu, veri persius vituperata ei nec. <strong>Scripta periculis ei eam, te pro movet
      reformidans.</strong> Pri posse graeco definitiones cu, id eam populo quaestio adipiscing, usu quod malorum te.
  </p>
  <p class="body">
    <a href="" class="cta">CTA Link</a>
  </p>
</div>